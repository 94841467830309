<template>
  <ion-page>
    <toolbar :title="$tr(trSignIn, 'forgot')" :backbutton="true" />
    <ion-content fullscreen class="ion-padding" scroll-y="false">
      <form v-if="!requested" @submit.prevent="forgot">
        <ion-item lines="none">
          <ion-text v-html="$tr(trSignIn, 'forgotText')"></ion-text>
        </ion-item>
        <ion-item>
          <ion-label position="floating">Email</ion-label>
          <ion-input v-model="email" id="email" required></ion-input>
        </ion-item>
        <section class="sign-margin">
          <ion-button type="submit" expand="block">
            {{ $tr(trCommon, 'continue') }}
          </ion-button>
        </section>
      </form>
      <form v-else @submit.prevent="password">
        <template v-if="!showResend">
          <section class="sign-margin" v-html="$tr(trSignIn, 'forgotSendText').replace('%email%', email)"></section>
          <ion-item class="sign-margin">
            <ion-label position="floating">{{ $tr(trSignIn, 'enterCode') }}</ion-label>
            <ion-input autocomplete="false" type="tel" v-model="code" id="code" name="code" required></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating">{{ $tr(trCommon, 'newPassword') }}</ion-label>
            <ion-input minlength="10" autocomplete="false" v-model="newPassword" id="newPassword" :type="showPwd ? 'text' : 'password'" required></ion-input>
            <ion-icon @click="showPassword" :icon="showPwd ? eye : eyeOff" class="pwd-icon" color="dark" slot="end"></ion-icon>
          </ion-item>
          <section class="sign-margin">
            <ion-button type="submit" expand="block">
              {{ $tr(trSignIn, 'sendCode') }}
            </ion-button>
          </section>
        </template>
        <template v-else>
          <section class="sign-margin" v-html="$tr(trSignIn, 'codeExpired').replace('%email%', email)"></section>
          <section class="sign-margin">
            <ion-button color="shiawasedo" type="button" @click="forgot" expand="block">
              {{ $tr(trSignIn, 'resendCode') }}
            </ion-button>
          </section>
        </template>
        <section class="sign-margin">
          <ion-button color="light" type="button" @click="cancel" expand="block">
            {{ $tr(trSignIn, 'cancelCode') }}
          </ion-button>
        </section>
      </form>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonText,
  IonButton,
  IonInput,
  IonItem,
  loadingController,
  alertController,
    IonLabel,
  IonIcon
} from "@ionic/vue";
import ApiService from "../services/api.service";
import {trCommon, trSignIn} from "../i18n/messages";
import Toolbar from "../components/Toolbar";
import { eye, eyeOff } from 'ionicons/icons';
import {TokenService} from "../services/token.service";
import {LanguageService} from "../services/language.service";
export default {
  name: "Forgot",
  components: {
    IonPage, IonContent, IonText, IonButton, IonInput, IonItem, Toolbar, IonLabel, IonIcon
  },
  data() {
    return {
      email: localStorage.getItem('forgot_request_email') ? localStorage.getItem('forgot_request_email') : null,
      requested: localStorage.getItem('forgot_request') === '1',
      newPassword: null,
      showPwd: false,
      showResend: false
    }
  },
  setup() {
    return {
      trSignIn,
      trCommon,
      eye,
      eyeOff
    }
  },
  methods: {
    showPassword() {
      this.showPwd = !this.showPwd;
    },
    async forgot() {
      const loading = await loadingController.create({})
      await loading.present()
      ApiService.post('forgot', {
        email: this.email
      }).then(() => {
        this.requested = true
        localStorage.setItem('forgot_request', '1')
        localStorage.setItem('forgot_request_email', this.email)
        loading.dismiss()
        this.showResend = false
      }).catch(error => {
        const response = error.response;
        this.alertError(response.data.meta.error_message)
        loading.dismiss();
        localStorage.removeItem('forgot_request')
        localStorage.removeItem('forgot_request_email')
      });
    },
    async password() {
      const loading = await loadingController.create({})
      await loading.present()
      ApiService.post('password', {
        email: this.email,
        code: this.code,
        password: this.newPassword
      }).then((res) => {
        if(res.status === 200) {
          TokenService.saveToken(res.data.data.access_token);
          LanguageService.setLangById(res.data.data.langId);
          TokenService.saveUser(res.data.data.id);
          TokenService.saveRefreshToken(res.data.data.refresh_token);
          ApiService.setHeader();
          ApiService.mount401Interceptor();
          localStorage.removeItem('forgot_request');
          localStorage.removeItem('forgot_request_email');
          loading.dismiss();
          window.location.reload()
        }
      }).catch(error => {
        const response = error.response;
        this.alertError(response.data.meta.error_message)
        loading.dismiss();
        if(response.data.meta.code === 406) {
          this.showResend = true
        } else {
          localStorage.removeItem('forgot_request')
          localStorage.removeItem('forgot_request_email')
        }
      });
    },
    cancel() {
      localStorage.removeItem('forgot_request')
      localStorage.removeItem('forgot_request_email')
      this.email = null
      this.requested = false

    },
    async alertError(text) {
      const alert = await alertController.create({
        header: this.$tr(trCommon, 'error'),
        message: text,
        buttons: ['OK'],
      });
      await alert.present();
    },
  }
}
</script>

<style scoped>
.pwd-icon {
  margin-top: 25px;
}
</style>